import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import EasySpeech from 'easy-speech';
import { Speech } from './Speech';
import WebCam from './WebCam';
import Body, {anim, read, doneRead, sleep, wake, random} from './Body';
import openSite from './openSite';
import Toast from './Toast';
import Answer from './Answer';
import SpesialWords from './SpesialWords';
let lang
let supportId = false
let isMobile = false

const checkDevice = () => {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    isMobile = true
  }
}

const checkLang = async() => {
  await EasySpeech.init({ maxTimeout: 5000, interval: 250 })
  .then(() => console.debug('load complete'))
  .catch(e => console.error(e))
  // Cek apakah support indonesia atau tidak
  let value = "en"
  const voices = await EasySpeech.voices()
  const languages = new Set()
  voices.forEach(voice => {
    languages.add(voice.lang.split(/[-_]/)[0])
  })

  if(languages.has("id")){
    value = "id"
    supportId = true
  }else{
    console.log("Browser kamu tidak mendukung Bahasa Indonesia")
  }

  let filteredVoices
  filteredVoices = voices
      .filter(voice => {
        return voice.lang.indexOf(`${value}-`) > -1 || voice.lang.indexOf(`${value}_`) > -1
      })
      .sort((a, b) => a.name.localeCompare(b.name))

  lang = filteredVoices[0]
}

checkLang()
checkDevice()

const Robot = () => {
  
  const [allowMirophone, setAllowMicrophone] = useState(false)
  const [allowCamera, setAllowCamera] = useState(true)
  // Check microphone
  const permissions = navigator.mediaDevices.getUserMedia({audio: true})
    permissions.then((stream) => {
      setAllowMicrophone(true)
    })
 
  const {
    transcript,
    resetTranscript,
    browserSupportsContinuousListening
  } = useSpeechRecognition();

  let seconds = 2;
  const [timeLeft, setTimeLeft] = useState(seconds)
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sleeping, setSleeping] = useState(false)
  const [cam, setCam] = useState(false)
  const [allowSpeak, setAllowSpeak] = useState(true)
  const [reading, setReading] = useState(false)

  useEffect(() => {
    if(ready){
      //Turn off microphone
      if (browserSupportsContinuousListening) {
        let language = ""
        if(supportId){
          language = 'id-ID'
        }else{
          language = "en-US"
        }
        SpeechRecognition.startListening({ continuous: true,language: language})
      } 
      //Waktu tidak akan berjalan jika belum ada transcipt  
      if(transcript != ""){
        // exit early when we reach 0
        if (!timeLeft) {
          setReady(false)
          Answer(lang,transcript,setLoading,setReading)    
          resetTranscript()
        }else{
          // Waktu terus berkurang jika masih diatas 0
          const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
          }, 1000);
          return () => clearInterval(intervalId);
        
        }
    
      }
    }else{
      //Turn off microphone
      if (browserSupportsContinuousListening) {
        SpeechRecognition.startListening({ continuous: true,language: "id-ID"})
      } 
    }
  });

  useEffect(() => {
    
    if(!ready && !reading && !loading){
      if(transcript.toLowerCase().includes("kabo") && !sleeping){
        anim("top");
        Speech("kulan",lang).then(() => {
          setReady(true);
          resetTranscript();
        }).catch((error) => {
          setAllowSpeak(false);
        });
        resetTranscript();
      }
      SpesialWords(transcript,resetTranscript,sleeping,setSleeping,setCam,setAllowCamera)
      if(!sleeping){
        openSite(transcript,resetTranscript)
      }
    }

    // Waktu diupdate ketika ada kata baru
    setTimeLeft(seconds);
  }, [transcript]);

 
  
  return (
    <div>
        {/*   FOR DEBUG */}
        {/* <p>Ready: {ready.toString()}</p>
        <p>{transcript}</p>
        <p>Time to start: {timeLeft}</p>
        <p>Reading: {reading.toString()}</p> */}
        <Body ready={ready} sleeping={sleeping} loading={loading}/>
        {cam &&
          <WebCam setCam={setCam}/>
        }
        {!supportId && 
          <Toast text={'Browsermu tidak mendukung Bahasa Indonesia. Gunakan bahasa Inggris untuk berbicara dengan Kabo'}/>
        }
        {!allowSpeak && 
          <Toast text={'Browsermu tidak menginzinkan robot untuk berbicara'}/>
        }
        {!allowCamera && 
          <Toast text={'Kabo tidak dapat mengambil foto. Kamu harus mengizinkan kabo untuk mengakses camera'}/>
        }
        {!allowMirophone && 
          <Toast text={'Kabo tidak bisa mendengarkanmu. Kamu harus mengizinkan kabo untuk mengakses camera & microphone'}/>
        }
        {isMobile && 
          <Toast text={'Saat ini Kabo tidak kompatibel dengan perangkat mobile. Silahkan buka pada perangkat komputer'} />
        }
    </div>
  );
};
export default Robot;