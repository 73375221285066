import EasySpeech from 'easy-speech'

export const Speech = async(text, lang) => {
  await EasySpeech.speak({
      text: text,
      pitch: 1.5,
      rate: 1.1,
      voice: lang,
      volume: 1,
      // there are more events, see the API for supported events
      boundary: e => console.debug('boundary reached')
    })
}
