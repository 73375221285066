import React, {useEffect, useState} from 'react';
let book
let headset 
let sleeping_anim 
let collection 
let wrap 
let isSleepng = false

    
// function backAnimate(){
//     // fungsi untuk memulai animasi seperti awal
//     setTimeout(function (){
//         for (let i = 0; i < collection.length; i++) {
//             collection[i].style.animationName = "blink"
//             collection[i].style.animationIterationCount = "infinite"
//         }
//         wrap.style.animationName = "none"
//         wrap.style.animationDuration = "5s"
//     }, 5000);

// }

function anim(name){
    // Animasi untuk kedua mata
    if(name == "double-blink" || name == "blink"){
        for (let i = 0; i < collection.length; i++) {
            collection[i].style.animationName = name
        }
    }else{
        wrap.style.animationName = name
        wrap.style.animationIterationCount = "1"
    }
}

function random(type) {
    var items = []
    if(type == "blink"){
        items = ["blink","double-blink"]
    }else{
        items = ["right","left"]
    }
    // Fungsi untuk random animasi mata
    anim(items[Math.floor(Math.random()*items.length)]);
}


function read(){
    for (let i = 0; i < collection.length; i++) {
        collection[i].style.animationName = "eyes-when-read"
        collection[i].style.animationIterationCount = 1
    }
    wrap.style.animationIterationCount = "infinite"
    wrap.style.animationName = "read"
    book.style.display = "inline-block"
    book.style.animationName = "fadeInBottom"
}

function doneRead(){
    for (let i = 0; i < collection.length; i++) {
        collection[i].style.animationName = "eyes-when-done-read"
        collection[i].style.animationIterationCount = 1
    }
    wrap.style.animationName = "none"
    book.style.animationName = "fadeOutBottom"
}

function sleep(){
    wrap.style.animationName = "none"
    for (let i = 0; i < collection.length; i++) {
        collection[i].style.animationName = "close-eyes"
        collection[i].style.animationIterationCount = 1
    }
    setTimeout(function (){
        headset.style.animationName = "take-off-headset"
    }, 4000);
    setTimeout(function (){
        sleeping_anim.style.display = "block"
    }, 6000);
}

function wake(){
    for (let i = 0; i < collection.length; i++) {
        collection[i].style.animationName = "open-eyes"
        collection[i].style.animationIterationCount = 1
    }
    setTimeout(function (){
        sleeping_anim.style.display = "none"
        headset.style.animationName = "take-on-headset"
    }, 4000);
}

const Body = (props) => {
    let animationBlinkTime = 6;
    let animationTime = 10;
    const [animationBlinkTimeLeft, setAnimationBlinkTimeLeft] = useState(animationBlinkTime);
    const [animationTimeLeft, setAnimationTimeLeft] = useState(animationTime);
    // Timer untuk animasi blink dan geser
    useEffect(() => {
        if(!props.ready){
            const animationInterval = setInterval(() => {
                if(!props.sleeping){
                    // Jika sedang loading tidak boleh animasi
                    if(!props.loading){
                        setAnimationTimeLeft(animationTimeLeft - 1)
                        setAnimationBlinkTimeLeft(animationBlinkTimeLeft - 1)
                    }
                }
            }, 1000);

            if(animationTimeLeft <= 0){
                random("move")
                setAnimationTimeLeft(animationTime)
            }

            // Animation for blink
            if(animationBlinkTimeLeft <= 0){
                random("blink")
                setAnimationBlinkTimeLeft(animationBlinkTime)
            }


            return () => clearInterval(animationInterval);
        }
    })

    book = document.querySelector(".book");
    headset = document.querySelector(".headset");
    sleeping_anim = document.querySelector("#sleeping");
    collection = document.getElementsByClassName("eye")
    wrap = document.getElementById("wrap")

    return (
        <div id='robot-body'>
            <div id="robot">
                <div id="sleeping">
                    <span>z</span>
                    <span>z</span>
                    <span>z</span>
                </div>
                <div id="wrap">
                    <div className="eye" />
                    <div className="eye" />
                </div>
                <img src="/assets/book.svg" className="book" alt="book" />
                <img src="/assets/headset.svg" className="headset" alt="headset" style={{width: '100%'}} />
            </div>
        </div>
    )
}

export default Body
export {anim, read, doneRead, sleep, wake, random}
