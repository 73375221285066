import React, {useRef, useState, useCallback, useEffect} from "react";
import Webcam from "react-webcam";

const WebCam = (props) => {
    // Timer
    let seconds = 5;
    const [timeLeft, setTimeLeft] = useState(seconds);
    useEffect(() => {
        if(imgSrc == null){
            if (!timeLeft) {
                capture()
            }else{
                // save intervalId to clear the interval when the
                // component re-renders
                const intervalId = setInterval(() => {
                    setTimeLeft(timeLeft - 1);
                }, 1000);
            
                // clear interval on re-render to avoid memory leaks
                return () => clearInterval(intervalId);
                // add timeLeft as a dependency to re-rerun the effect
                // when we update it
            }
        }
    })

    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc]);

    const Recap = useCallback(() => {
        setTimeLeft(seconds)
        setImgSrc(null)
    })
   
    const closeCam = () => {
        props.setCam(false)
    }

    
    const portrait = {
        width: { min: 340 },
        height: { min: 480 },
        aspectRatio: 340/480
      };

    const landscape = {
        width: { min: 540 },
        height: { min: 360 },
        aspectRatio: 540/360
    };

    return (
    <>
        <div id="Webcam">
            <button id="close-cam" onClick={closeCam}><b>X</b></button>
            {imgSrc === null &&
                <div>
                    <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={window.innerWidth < 700 ? portrait : landscape}
                    />
                    <div className="centered">
                        <p>{timeLeft}</p>
                    </div>   
                </div>       
                }
            {imgSrc && (
                <div>
                    <img
                        src={imgSrc}
                    />
                    <br></br>
                </div>
            )}
            <div style={{float: 'right'}}>
                <button disabled={imgSrc ? false : true} style={{backgroundColor: 'transparent',border:'none',cursor:'pointer'}} onClick={Recap}><img src="/assets/recap.svg" width={25}/></button>
                <button disabled={imgSrc ? false : true} style={{backgroundColor: 'transparent',border:'none',cursor:'pointer'}}><a id="download" href={imgSrc} download style={{textDecoration: 'none'}}><img src="/assets/download.svg" width={25}/></a></button>
            </div>
        </div>
    </>
    );
};
  
export default WebCam;