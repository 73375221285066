import React, { useState } from 'react'
import Collapsible from 'react-collapsible';

function Guide(props) {
    const [modal, setModal] = useState(false)

    const open = () => {
        setModal(true)
    }

    const close = () => {
        setModal(false)
    }
    return (
        <div>
            {modal ? ( 
            <div id='guide'>
                <div id='guide-content'>
                    <button id='guide-close' onClick={close}>X</button>
                    <p style={{fontSize: '18px',fontWeight: 'bold'}}>Halo! Saya Kabo👋</p>
                    <p style={{textAlign:'justify'}}>{props.name} adalah robot pintar (kadang tidak) berbasis website yang dibuat menggunakan API Gemini dari Google. {props.name} terinspirasi dari robot-robot pintar yang dibuat menggunakan teknologi AI yang dapat berinteraksi dengan manusia</p>
                    <Collapsible trigger="Kabo🤖">
                        <p>Untuk memulai percakapan dengan {props.name} kamu harus memanggil namanya. Jika {props.name} menjawab "kulan" artinya dia siap untuk memulai percakapan. </p>
                    </Collapsible>
                    <p>Special Words :</p>
                    <p>{props.name} juga punya beberapa kata spesial yang bisa kamu ucapkan. Untuk menggunakan kata-kata spesial itu kamu tidak perlu memanggil namanya terlebih dahulu.</p>
                    <Collapsible trigger="Tidur😴">
                        <p>Perintah ini akan membuat {props.name} tertidur. Ketika dia tertidur dia tidak akan mendengarkanmu sebelum kamu membangunkannya</p>
                    </Collapsible>
                    <Collapsible trigger="Bangun🤩">
                        <p>{props.name} akan terbangun dari tidurnya dan siap berinteraksi denganmu</p>
                    </Collapsible>
                    <Collapsible trigger="Selfie/Swafoto📸">
                        <p>Jika kamu mengatakan "Swafoto" atau "Selfie", {props.name} akan mengambil foto menggunakan kameramu.</p>
                    </Collapsible>
                    <Collapsible trigger="Buka Website🔗">
                        <p>{props.name} bisa membawamu menuju website yang kamu inginkan hanya dengan mengatakan "Buka [nama website]". Contoh "Buka YouTube". Beberapa website yang bisa dibuka oleh {props.name} Diantaranya YouTube, Instagram, Tiktok, Twitter, Github, Facebook & Spotify</p>
                    </Collapsible>
                    <p style={{textAlign:'center'}}>Made with ❤️ by <a href='https://instagram.com/noosabaktee' target='_blank' style={{fontWeight: 'bold',color:'white'}}>Rama Nusa Bakti</a></p>
                </div>
            </div>
            ) : (
            <button id='guide-open' onClick={open}>
                <img src='/assets/guide.svg' width={50}/>
            </button> )
            }       
        </div>
    )
}

export default Guide