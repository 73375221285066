import './App.css';
import Robot from './Robot';
import Guide from './Guide';

function App() {
  const name = "Kabo"

  return (
    <div className="App">
      <div style={{"color":"white"}}>
        <Robot />
        <Guide name={name}/>
      </div>
    </div>
  );
}

export default App;
