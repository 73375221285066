import { Speech } from './Speech';
import { read, doneRead } from './Body';

const Answer = (lang, transcript, setLoading, setReading) => {
    const url = `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=${process.env.REACT_APP_API_KEY}`;
  
    const data = JSON.stringify({
      'contents': [
        {
          'parts': [
            {
              'text': transcript
            }
          ]
        }
      ]
    })


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data
    };
    setLoading(true)
    read()
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(res =>{ 
        setReading(true)
        Speech(res.candidates[0].content.parts[0].text, lang).then(() => {
          setReading(false)
        })
        setLoading(false)
        doneRead()
      }); 
}

export default Answer