function openSite(transcript, resetTranscript) {
    if(transcript.toLowerCase().includes("open youtube") ||transcript.toLowerCase().includes("buka youtube")){
        window.open('https://youtube.com', '_blank')
        resetTranscript()
    }else if(transcript.toLowerCase().includes("open instagram") ||transcript.toLowerCase().includes("buka instagram")){
        window.open('https://instagram.com', '_blank')
        resetTranscript()
    }else if(transcript.toLowerCase().includes("open tik tok") ||transcript.toLowerCase().includes("buka tik tok")){
        window.open('https://tiktok.com', '_blank')
        resetTranscript()
    }else if(transcript.toLowerCase().includes("open facebook") ||transcript.toLowerCase().includes("buka facebook")){
        window.open('https://facebook.com', '_blank')
        resetTranscript()
    }else if(transcript.toLowerCase().includes("open spotify") ||transcript.toLowerCase().includes("buka spotify")){
        window.open('https://spotify.com', '_blank')
        resetTranscript()
    }else if(transcript.toLowerCase().includes("open twitter") ||transcript.toLowerCase().includes("buka twitter")){
        window.open('https://twitter.com', '_blank')
        resetTranscript()
    }else if(transcript.toLowerCase().includes("open github") ||transcript.toLowerCase().includes("buka twitter")){
        window.open('https://github.com', '_blank')
        resetTranscript()
    }
}

export default openSite