import React, { useState } from 'react'

function Toast(props) {
  const [show,setShow] = useState(true)

  const close = () => {
    setShow(false)
  }

  return (
    <div>
      {show &&
        <div id="snackbar" className='show'>
          <button onClick={close}>X</button>
          <div style={{position:'relative',width:'90%'}}>{props.text}</div>
        </div>
      }
    </div>
  )
}

export default Toast