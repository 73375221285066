import { sleep, wake } from "./Body"

function SpesialWords(transcript,resetTranscript,sleeping,setSleeping,setCam,setAllowCamera) {
    if((transcript.toLowerCase().includes("tidur") || transcript.toLowerCase().includes("sleep")) && !sleeping){
        sleep()
        setSleeping(true)
        resetTranscript()
      }else if((transcript.toLowerCase().includes("bangun")|| transcript.toLowerCase().includes("wake")) && sleeping){
        wake()
        setSleeping(false)
        resetTranscript()
      }else if((transcript.toLowerCase().includes("swafoto") || transcript.toLowerCase().includes("selfie"))  && !sleeping){
        const cameraPermissions = navigator.mediaDevices.getUserMedia({video: true})
        cameraPermissions.then((stream) => {
          setCam(true)
        }).catch(() => {
          setAllowCamera(false)
        })
        resetTranscript();
      }
}

export default SpesialWords